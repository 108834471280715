import Home from "./pages/Home";
import "./App.css"; // globals css

function App() {
  return (
    <Home/>
  );
}

export default App;
